<i18n>
  {
    "en": {
      "security": "Security",
      "changePassword": "Change Password",
      "currentPassword": "Current Password",
      "newPassword": "New Password",
      "retypeNewPassword": "Retype New Password",
      "warning": "Your account is synced with intranet. Please change your password on intranet. If you no longer have access to intranet, please contact the administrator.",
      "passwordNotMatch": "Password not match"
    },
    "th": {
      "security": "ความปลอดภัย",
      "changePassword": "เปลี่ยนรหัสผ่าน",
      "currentPassword": "รหัสผ่านปัจจุบัน",
      "newPassword": "รหัสผ่านใหม่",
      "retypeNewPassword": "พิมพ์รหัสผ่านใหม่อีกครั้ง",
      "warning": "บัญชีของท่านผูกกับ intranet กรุณาเปลี่ยนรหัสผ่านใน intranet หากท่านไม่มีสิทธิ์เข้าถึง intranet กรุณาติดต่อผู้ดูแลระบบ",
      "passwordNotMatch": "รหัสผ่านไม่ตรงกัน"
    }
  }
</i18n>

<template>
  <v-dialog v-model="localShow" width="500">
    <v-card class="rounded-xl">
      <v-toolbar dense flat class="transparent">
        <v-icon left color="primary">lock</v-icon>
        <h3>
          {{ $t("changePassword") }}
        </h3>
      </v-toolbar>
      <v-card-text>
        <v-container fluid v-if="isPasswordSynced">
          <v-row dense>
            <v-col>
              {{ $t("warning") }}
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid v-if="!isPasswordSynced">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                outlined
                :label="$t('currentPassword')"
                type="password"
                v-model="currentPassword"
                :autocomplete="false"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                :label="$t('newPassword')"
                type="password"
                v-model="newPassword"
                :autocomplete="false"
                hide-details="auto"
                :rules="[checkPassword]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                :label="$t('retypeNewPassword')"
                type="password"
                v-model="retypeNewPassword"
                :autocomplete="false"
                hide-details="auto"
                :rules="[checkPassword]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded color="primary" v-if="!isPasswordSynced" @click="submit">
          <v-icon left>save</v-icon>
          {{ $t("save") }}
        </v-btn>
        <v-btn rounded color="primary" v-else @click="localShow = false">
          <v-icon left>close</v-icon>
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: { show: Boolean, user: Object },
  data: () => ({ currentPassword: "", newPassword: "", retypeNewPassword: "" }),
  methods: {
    async submit() {
      const post = {
        employeeId: this.user.employeeid,
        password: this.newPassword,
      };
      const { data } = await axios.put("/api/user/password/update", post, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("logbook-token")}`,
        },
      });
      console.log(data);
    },
  },
  computed: {
    localShow: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit("update:show", v);
      },
    },
    isPasswordSynced() {
      return this.user?.password_sync == true;
    },
    checkPassword() {
      if (this.newPassword != this.retypeNewPassword) {
        return this.$t("passwordNotMatch");
      }
      return true;
    },
  },
};
</script>

<style>
</style>